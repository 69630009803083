/* ### Begin: Cross-browser normalization ### */
/* Normalize problems across browsers */
/* Note: Before final deployment you can take out HTML elements that are not used in markup to save on file-size */

html {
	box-sizing: border-box;		/* Cross-browser box model equalization */
	overflow-y: scroll;			/* Force a vertical scrollbar on pages that have none, avoiding content jumping between pages */
	text-size-adjust: 100%;		/* Prevent text size adjust after orientation change, without disabling user zoom */
}

*, *::before, *::after {
	box-sizing: inherit;		/* Inherit cross-browser box model normalization yet allows for easier changes to box-sizing in components */
	margin: 0;					/* Cross browser margin normalisation */
	padding: 0;					/* Cross browser padding normalisation */
	vertical-align: baseline;	/* Cross browser text-alignment normalisation */
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
	display: block;				/* Correct block display not defined for HTML5 element in IE8/9, not defined for details or summary in IE10/11/Firefox and main in IE11 */
}

button {
	overflow: visible;			/* Address overflow set to hidden in IE8—11 */
}

button, html [type='button'], [type='reset'], [type='submit'] {
	-webkit-appearance: button;	/* Correct inability to style clickable input types in iOS, Correct WebKit bug in Android 4.0.* destroying audio and video controls */
}

button, input, optgroup, option, select, textarea {
	color: inherit;				/* Correct color not being inherited, Note: Affects color of disabled elements */
	font: inherit;				/* Correct font family, size and weight properties not being inherited by default */
	margin: 0;					/* Address margin differences between Firefox 4+, Safari and Chrome */
}

button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;					/* Remove inner border in Firefox 4+ */
	padding: 0;					/* Remove inner padding in Firefox 4+ */
}

img, svg {
	max-width: 100%;			/* Shrink-wrap elements to a maximum width of 100% of parent to make them responsive by default */
}

input {
	line-height: normal;		/* Address Firefox 4+ setting line-height !important on input elements through its UA stylesheet */
}

[type='checkbox'], [type='radio'] {
	padding: 0;					/* Remove excess padding in IE8/9/10 */
}
svg:not(:root) {
	overflow: hidden;			/* Correct overflow not hidden in IE9/10/11 */
}

/* ### End: Cross-browser normalization ### */



/* ### Begin: CSS Mixins and Variables ### */
/* Note: Uses latest CSS syntax, compile with cssnext.io in order to past-proof for legacy browsers, also consult caniuse.com */

:root {
	
	@custom-media --mobile (max-width: 38em);
	
	@custom-media --desktop (min-width: 76em);

	/* ### Begin: Mixins ### */
	
	/* Typography */
	--small-caps: {
		font-variant-caps: small-caps;
		@supports (font-variant-caps: small-caps) {
			font-feature-settings: normal;
		}
	}
	
	
	/* Animation */
	--transition-in: {
		transition: color .128s ease-in-out, background .128s ease-in-out;
	};
	--transition-out: {
		transition: color .256s ease-in-out, background .256s ease-in-out;
	};
	
	/* Lists */
	
	--listed-items: {
		display: inline-block;
		list-style-type: none; /* Remove bullets */
		&:before {
			content: "\200B"; /* +++Fix for Safari/VoiceOver; add a zero-width space to return list semantics [https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/] */
		}
	}
	
	/* ### End: Mixins ### */
	
	
	
	/* ### Begin: Variables ### */
	
	/* Color palette */
	--black: #2e3536;
	--white: #fff;
	--color-offwhite: #f9f9f9;
	--color-textsupport: #69797a;
	
	--color-brand-slash: #f90707;
	
	--color-anchor: #007d82;
	--color-anchor: #006366;
	--color-anchor-over: #827d00;
	--color-anchor-over: #5c5900;
	--color-anchor-active: var(--color-anchor);
	--color-highlight: #ff0;
	
	/* Modular scale */
	
	--size-base: 1.296rem;

	--size-1: calc(var(--size-base) * 1.15);
	--size-2: calc(var(--size-base) * 1.3);
	--size-3: calc(var(--size-base) * 1.45);
	--size-4: calc(var(--size-base) * 1.6);
	--size-5: calc(var(--size-base) * 1.75);
	--size-6: calc(var(--size-5) * 1.75);
	--size-7: calc(var(--size-6) * 1.75);
	
	--size--1: calc(var(--size-base) * .75);
	--size--2: calc(var(--size-base) * .6);
	--size--3: calc(var(--size-base) * .45);
	--size--4: calc(var(--size-base) * .3);
	
	/* Typography */
	--line-height: 1.45;
	
	/* Layout */
	--vertical-rhythm: var(--size-4);	/* Single-direction margin providing vertical rhythm */
	--padding: .68rem;
	--form-element-height: var(--vertical-rhythm);
	
	/* ### End: Variables ### */
}



/* ### Begin: Typefaces ### */
/* Note: Treat webfonts as progressive enhancement and fallback fonts as baseline experience */

/* Equitan Sans Regular */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/equitan-sans.woff2') format('woff2'), url('../fonts/equitan-sans.woff') format('woff');
}

/* Equitan Sans Regular Italic */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSans';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/equitan-sans-i.woff2') format('woff2'), url('../fonts/equitan-sans-i.woff') format('woff');
}

/* Equitan Sans Bold */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/equitan-sans-b.woff2') format('woff2'), url('../fonts/equitan-sans-b.woff') format('woff');
}

/* Equitan Sans Bold Italic */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSans';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/equitan-sans-bi.woff2') format('woff2'), url('../fonts/equitan-sans-bi.woff') format('woff');
}

/* Equitan Sans Light */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSans-Light';
	src: url('../fonts/equitan-sans-l.woff2') format('woff2'), url('../fonts/equitan-sans-l.woff') format('woff');
}

@font-face {
	font-display: fallback;
	font-family: 'EquitanSans-LightItalic';
	src: url('../fonts/equitan-sans-li.woff2') format('woff2'), url('../fonts/equitan-sans-li.woff') format('woff');
}

/* Equitan Slab Regular */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSlab';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/equitan-slab.woff2') format('woff2'), url('../fonts/equitan-slab.woff') format('woff');
}

/* Equitan Slab Bold */
@font-face {
	font-display: fallback;
	font-family: 'EquitanSlab';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/equitan-slab-b.woff2') format('woff2'), url('../fonts/equitan-slab-b.woff') format('woff');
}

/* ### End: Typefaces ### */



/* ### Begin: Layout ### */

header {
	display: flex;
}

main {
	margin-left: 3px;
}

article {
	margin: 0 auto;
	max-width: 36em;
	min-height: 100vh;
	padding: var(--size-7)  0;
}

article > section {
	margin-bottom: var(--size-6);
	& section {
		margin-bottom: 1.5rem;
	}
}

aside {
	margin-left: 3px;
	& header {
		padding: var(--size-1) 0;
	}
	& h1 {
		font-size: var(--size-1);
	}
}

/* ### End: Layout ### */



/* ### Begin: Typography ### */

body {
	background: var(--white) linear-gradient(0deg,#f90707 1%,#f9f907 25%,#07f907 50%,#07f9f9 75%,#0707f9);
    background-attachment: fixed;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 3px 100%;
	color: var(--black);
	font-family: EquitanSans, sans-serif;
	font-size: 100%;
}


/* Headings */
h1, h2, h3, h4, h5, h6 {
	font-family: EquitanSlab, sans-serif;
	font-feature-settings: 'kern' 1, 'liga' 1, 'pnum' 1, 'tnum' 0, 'onum' 1, 'lnum' 0, 'dlig' 1;
	font-size: calc(var(--size-5) * 1);
	font-weight: 400;
	line-height: 1.15;
	margin-bottom: .6rem;
	position: relative;
	& a[href^='#'] {
		border-bottom: none;
		margin-left: 1rem;
		opacity: 0;
		position: absolute;
	}
	&:hover {
		& a[href^='#'] {
			opacity: 1;
		}
	}
}
h2 {
	font-size: calc(var(--size-4) * 1);
}
h3 {
	font-size: calc(var(--size-3) * 1);
}
h4 {
	font-size: calc(var(--size-2) * 1);
}
h5 {
	font-size: calc(var(--size-1) * 1);
}
h6 {
	font-size: calc(var(--size-base) * 1);
}

/* Paragraphs */
p {
	margin-bottom: var(--vertical-rhythm);
	max-width: 33em;
}

p, li, address, label {
	font-size: var(--size-base);
	line-height: var(--line-height);
}

p + p {
	/* text-indent: var(--vertical-rhythm); */
}

/* Links */
a {
	@apply --transition-out;
	background: transparent;
	border-bottom: 1px solid currentcolor;
	color: var(--color-anchor);
	text-decoration: none;
	/* text-decoration-skip-ink: auto; /* Don’t cut through descenders */
}
a:visited {
	color: var(--color-anchor);
}
a:visited:hover {
	@apply --transition-in;
	color: var(--color-anchor-over);
}
a:focus, a:hover {
	color: var(--color-anchor-over);
}
a:active {
	background: var(--color-anchor);
	color: var(--white);
}
a:active:hover {
	background: var(--color-anchor);
	color: var(--white);
}

[rel='external'] {
}

/* Microtypography */
abbr, .scaps {
	@apply --small-caps;
	letter-spacing: .045rem;
	line-height: normal;
	font-style: normal;
}

abbr, dfn {
	@apply --transition-out;
	border-bottom: 3px solid transparent;
	cursor: help;
	&:hover {
		@apply --transition-in;
		border-bottom: 3px solid var(--color-anchor-over);
	}
}

abbr[title] {
	text-decoration: none;
}

address {
	font-style: normal;
}

b, strong {
	font-weight: 700;
}

blockquote, q {
	quotes: '\201C' '\201D' '\2018' '\2019';
}
blockquote:before, q:before {
	content: '\201c';
}
blockquote:after, q:after {
	content: '\201d';
}
blockquote {
	& footer {
		&:before {
			content: '— ';
		}
	}
}

dfn {
	font-style: normal;
}

footer {
	background: var(--color-offwhite);
	margin-left: 3px;
	padding: var(--size-1);
	text-align: right;
	& p, & small {
		font-size: var(--size--1);
		max-width: 100%;
	}
}

small {}

sub, sup {
	line-height: 0;
	position: relative;
}
sub {
	vertical-align: sub;
}
sup {
	vertical-align: super;
}

/* ### End: Typography ### */



/* ### Begin: Images ### */

img {
	border: 0;
	color: var(--black);	/* In case images are blocked, or fail to load */
}

figure {
	margin-bottom: var(--size-3);
	max-width: 38em !important;
	& a {
		background: transparent !important;
		border-bottom: none;
		outline: transparent !important;
	}
	& img {
		@apply --transition-out;
		box-shadow: 0 0 var(--size-1) var(--color-textsupport);
		margin-bottom: var(--size-base);
		max-width: 100%;
		&:hover {
			@apply --transition-in;
			box-shadow: 1px 1px 0 0 var(--color-anchor-over), 0 0 0 1px var(--color-anchor-over);
		}
		&:active, &:focus {
			@apply --transition-in;
			box-shadow: 1px 1px 0 0 var(--color-anchor-over), 0 0 0 1px var(--color-anchor-over);
		}
	}
}

figcaption {
	color: var(--color-textsupport);
	font-size: var(--size-base);
}

/* ### End: Images ### */



/* ### Begin: Forms ### */

fieldset {
	background: var(--color-offwhite);
	border: 0;
	display: inline-block;
	display: flex;
	margin: var(--vertical-rhythm) 0;
	padding: 1rem;
	width: auto;
}

button, [type='button'], [type='reset'], [type='submit'], [type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url'] {
	font-size: var(--size-1);
}

button, [type='button'], [type='reset'], [type='submit'] {
	@apply --transition-out;
	background: var(--color-anchor);
	color: var(--white);
	padding: .45em .6em;
	&:hover {	
		@apply --transition-in;
		background: var(--color-anchor-over);
	}
}

button:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus {
}

button:active, [type='button']:active, [type='reset']:active, [type='submit']:active {
}

button[disabled='disabled'], [type='button'][disabled='disabled'], [type='reset'][disabled='disabled'], [type='submit'][disabled='disabled'] {
	cursor: not-allowed;
}

button, [type='button'], [type='checkbox'], [type='file'], [type='radio'], [type='reset'], [type='submit'], label, select {
	cursor: pointer;
}

[type=email], [type=tel], [type=text], textarea {
	border: 1px solid #007d82;
	border-width: 0 0 3px;
	display: block;
	font-size: var(--size-1);
	padding: .45em .6em;
}

[type=email], [type=tel], [type=text], textarea {
	width: 100%;
}

form fieldset [type='email'], form fieldset [type='number'], form fieldset [type='password'], form fieldset [type='search'], [type='tel'], form fieldset [type='text'], form fieldset [type='url'], form fieldset textarea {
	display: block;
	margin-bottom: var(--vertical-rhythm);
}

[type='email']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='url']:hover, textarea:hover {
}

[type='email']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='url']:focus, textarea:focus {
}

[type='email']:active, [type='number']:active, [type='password']:active, [type='search']:active, [type='tel']:active, [type='text']:active, [type='url']:active, textarea:active {	
}

textarea {
	height: calc(var(--size-5) * 10);
	resize: vertical;
}

[type=email], [type=tel], [type=text], textarea {
	width: 100%;
}

/* ### End: Forms ### */

.logo {
	background: none !important;
	border: none !important;
	height: var(--size-4);
	outline: none !important;
	margin: 0 var(--size-1);
    width: calc(var(--size-4) * 2);
}

.main-nav {
	& li {
		display: inline-block;
		list-style: none;
		margin: 0 0 var(--size-1) 3px;
	}
	& a {
		background: var(--color-anchor);
		border-bottom: none;
		color: var(--white);
		font-family: EquitanSlab, sans-serif;
		font-size: var(--size-1);
		font-weight: 400;
		padding: .45rem 1.15rem;
		&:hover {
			background: var(--color-anchor-over);
			color: var(--white);
		}
	}
}

p, .list-ui {
	font-family: EquitanSans-Light, sans-serif;
	font-size: var(--size-1);
}

a:active, a:focus {
	background: var(--color-anchor);
	border-bottom: none;
	outline: 1px solid var(--color-anchor);
	color: var(--white);
	&:hover {
		background: var(--color-anchor-over);
		color: var(--white);
	}
}

li p {
	margin-bottom: 0;
}

.heading-tags {
	font-size: var(--size-base);
}

.list-tags li {
	@apply --listed-items;
	color: var(--color-textsupport);
	font-size: var(--size--1);
	&:not(:last-of-type):after {
		content: ', ';
	}
}

.list {
	& li {
		list-style: none;
		&:before {
			@apply --transition-out;
			color: var(--color-anchor-over);
			content: '•';
			font-family: EquitanSlab, sans-serif;
			font-size: var(--size-5);
			margin: -.6rem 0 0 -1.15rem;
			position: absolute;
		}
		&:hover:before {
			@apply --transition-in;
			color: var(--color-brand-slash);
		}
	}
}

.list-ui {
	& li {
		font-feature-settings: 'lnum';
		list-style: none;
	}
}

figcaption, small {
	font-size: var(--size--1);
}

.projects {
	& h2 {
		display: none;
	}
}

.list-projects {
	& li {
		list-style: none;
		margin-top: var(--size-6);
		position: relative;
		width: 100%;
	}
	& h3 {
		@apply --transition-out;
		background: var(--color-anchor);
		bottom: var(--size-3);
		color: var(--white);
/* 		display: inline; */
		font-size: var(--size-3);
		padding: .3rem 1rem;
		position: absolute;
	}
	& a {
		border-bottom: none;
		&:hover {
			& h3 {
				@apply --transition-in;
				background: var(--color-anchor-over);
			}
		}
	}
}

.breadcrumb {
	& li {
		color: var(--color-textsupport);
		display: inline-block;
		margin-bottom: var(--vertical-rhythm);
		font-size: var(--size--1);
		&:not(:last-of-type):after {
			content: '\203A';
			font-family: EquitanSlab, sans-serif;
			margin: 0 .45rem;
		}
	}
}

.project-metadata {
	color: var(--color-textsupport);
	font-feature-settings: 'lnum';
	margin-bottom: var(--vertical-rhythm);
	& li {
		list-style: none;
	}
	& h4 {
		font-family: EquitanSans, sans-serif;
		font-size: var(--size-base);
	}
}

.nav-project {
	display: flex;
	flex-wrap: wrap-reverse;
	justify-content: space-between;
	width: 100%;
	& li {
		display: inline-block;
		font-size: var(--size--1);
		&:last-of-type {
			align-self: flex-end;
			&:after {
				content: '\203A';
				margin-left: var(--size--4);
			}
		}
		&:first-of-type:before {
				content: '\2039';
				margin-right: var(--size--4);
		}
	}
}

.slab {
		font-family: EquitanSlab, sans-serif;
}


.portrait {
	margin: var(--size-7) 0;
}

.brand-slash {
	color: var(--color-brand-slash);
}

@media (--mobile) {
	main {
		padding: var(--size-base);
	}
}

@media (--desktop) {
	aside {
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: space-around;
		padding: 3rem;
		position: fixed;
		& header {		
			flex-direction: column;
		}
		& h1 {
			font-size: var(--size-3);
		}
		& p {
			font-size: var(--size--1);
		}
	}
	.logo {
		margin: var(--size-1) auto;
	    max-width: calc(var(--size-5) * 2);
	}
	.main-nav {
		& li {
			display: block;
			margin-bottom: 1.15rem;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		font-size: calc(var(--size-5) * 2);
	}
	h2 {
		font-size: calc(var(--size-4) * 2);
	}
	h3 {
		font-size: calc(var(--size-3) * 2);
	}
	h4 {
		font-size: calc(var(--size-2) * 2);
	}
	h5 {
		font-size: calc(var(--size-1) * 2);
	}
	h6 {
		font-size: calc(var(--size-base) * 2);
	}
	
	footer {	
		flex-direction: row;
		& p {
			margin-bottom: 0;
		}
	}
}

/* ### Begin: Print styling ### */

@media print {

	* {
		background: transparent !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
    }

    a, a:visited {
		border-bottom: 1px dotted var(--black);
    }
    
    a[href]:after {
		content: ' (' attr(href) ')';
    }
	a[href^='javascript:']:after, a[href^='#']:after {
		content: '';
    }
    
    abbr[title]:after {
		content: ' (' attr(title) ')';
    }
    
    blockquote, img, pre, table, tr {
	    page-break-inside: avoid;
    }
    
	h1, h2, h3, h4, h5, h6 {
		page-break-after: avoid;
	}
    
	nav {
		display: none;
	}

}

/* ### End: Print styling ### */